import { Routes, Route, Navigate } from "react-router-dom";

import HomeLayout from "./layout/HomeLayout";
import About from "./page/About";
import Home from "./page/Home";
import Contact from "./page/Contact";
import Privacy from "./page/Privacy";
import Terms from "./page/Terms";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="terms" element={<Terms />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Route>
    </Routes>
  );
}
