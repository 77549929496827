import { Card, CardContent, CardMedia, Typography } from "@mui/material";

export default function Contact() {
  return (
    <>
      <Typography variant="h4" color="text.secondary" textAlign="center">
        Contact Us
      </Typography>
      <Card sx={{ mt: 5, borderRadius: 10 }}>
        <CardMedia component="img" image="/image/contact.jpg" />
        <CardContent>
          <Typography variant="h5" component="div" textAlign="center">
            You can contact us anytime
          </Typography>
          <Typography variant="h6" component="div" textAlign="center">
            using our email
          </Typography>
          <Typography
            variant="h6"
            component="div"
            textAlign="center"
            color="text.secondary"
          >
            chsappz@gmail.com
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
