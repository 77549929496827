import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

const DATA = [
  {
    image: "/image/hmanager.png",
    title: "HManager",
    description: "Heroku Android app",
  },
  {
    image: "/image/ballroll.png",
    title: "BallRoll",
    description: "Small and fun Android game",
  },
  {
    image: "/image/userge.png",
    title: "Userge",
    description: "Powerful Telegram userbot",
  },
  {
    image: "/image/tgfiles.jpg",
    title: "TgFiles",
    description: "Telegram file stream bot",
  },
  {
    image: "/image/tools.jpg",
    title: "Tools",
    description: "Useful tools",
  },
  {
    image: "/image/blog.jpg",
    title: "Blog",
    description: "Helpful articles",
  },
];

function Item({ item }) {
  return (
    <Card sx={{ borderRadius: 10 }}>
      <CardActionArea href={`https://${item.title}.chsappz.com`}>
        <Stack direction={{ sm: "column", md: "row" }}>
          <CardMedia
            component="img"
            sx={{ width: { sm: "auto", md: 200 } }}
            image={item.image}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {item.title}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {item.description}
            </Typography>
          </CardContent>
        </Stack>
      </CardActionArea>
    </Card>
  );
}

export default function Home() {
  return (
    <Grid container spacing={3}>
      {DATA.map((item, index) => (
        <Grid item xs={12} sm={6} key={index}>
          <Item item={item} />
        </Grid>
      ))}
    </Grid>
  );
}
