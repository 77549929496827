import { Card, CardContent, CardMedia, Typography } from "@mui/material";

export default function About() {
  return (
    <>
      <Typography variant="h4" color="text.secondary" textAlign="center">
        About Us
      </Typography>
      <Card sx={{ mt: 5, borderRadius: 10 }}>
        <CardMedia component="img" image="/image/about.jpg" />
        <CardContent>
          <Typography variant="h5" component="div" textAlign="center">
            We are ChsAppz
          </Typography>
          <Typography variant="h6" component="div" textAlign="center">
            We provide useful online services for free
          </Typography>
          <Typography
            variant="h6"
            component="div"
            textAlign="center"
            color="text.secondary"
          >
            Enjoy :)
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
